@font-face {
  font-family: 'Bebas Neue';
  src: url('/static/fonts/BebasNeue-Regular.woff2');
}

@font-face {
  font-family: 'Lato';
  src: url('/static/fonts/Lato-Regular.woff2');
}

@font-face {
  font-family: 'Open Sans';
  src: url('/static/fonts/OpenSans-Regular.woff2');
}

@font-face {
  font-family: 'Open Sans Bold';
  src: url('/static/fonts/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'Font Awesome 5 Free-Solid-900';
  src: url('/static/fonts/Font Awesome 5 Free-Solid-900.otf') format('opentype');
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: rgb(51, 51, 51);
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
  /* theme specific styles */
  font-family: 'Open Sans', sans-serif;
  color: rgb(51, 51, 51);
}

.bold {
  font-family: 'Open Sans Bold', sans-serif;
}
